import xhr from './index.js'

// 列表
export function clueList(param) {
  return xhr({
    url: 'admin/v1/clue/list',
    body: param,
    method: "post"
  })
}
// 新增
export function insertClue(param) {
  return xhr({
    url: 'admin/v1/clues',
    body: param,
    method: "post"
  })
}
// 编辑
export function modifyClue(id, param) {
  return xhr({
    url: 'admin/v1/clues/' + id,
    body: param,
    method: "put"
  })
}
// 删除
export function deleteClue(id) {
  return xhr({
    url: 'admin/v1/clues/' + id,
    method: "delete"
  })
}
// 分配
export function assignClue(param) {
  return xhr({
    url: 'admin/v1/clue/assign',
    body: param,
    method: "POST"
  })
}
// 无效线索
export function setInvalidClue(param) {
  return xhr({
    url: 'admin/v1/clue/set-invalid',
    body: param,
    method: "post"
  })
}
// 转化商机
export function transformBusiness(param) {
  return xhr({
    url: 'admin/v1/businesses',
    body: param,
    method: "post"
  })
}
// 新增沟通记录
export function insertChat(param) {
  return xhr({
    url: 'admin/v1/company/chat-add',
    body: param,
    method: "post"
  })
}
// 沟通记录列表
export function chatList(param) {
  return xhr({
    url: 'admin/v1/company/chat-list',
    body: param,
  })
}


// 沟通记录列表
export function delChat(param) {
  return xhr({
    url: 'admin/v1/company/chat-del',
    body: param,
    method: "post"
  })
}

// 沟通记录列表
export function clueShare(param) {
  return xhr({
    url: 'admin/v1/clue/share',
    body: param,
    method: "get"
  })
}

// 线索导入
export function importclue(param) {
  return xhr({
    url: 'admin/v1/gb-clue/import',
    body: param,
    method: "post"
  })
}

// 线索列表
export function getClueList(param) {
  return xhr({
    url: 'admin/v1/gb-clues',
    body: param,
    method: "get"
  })
}

// 查看人数明细
export function getContactDetails(param) {
  return xhr({
    url: 'admin/v1/gb-clue/get-contact-details',
    body: param,
    method: "get"
  })
}

// 线索编辑
export function editclue(param) {
  return xhr({
    url: `admin/v1/gb-clues/${param.id}`,
    body: param,
    method: "PUT"
  })
}
// 线索删除
export function deleteclue(param) {
  return xhr({
    url: `admin/v1/gb-clue/batch-del`,
    body: param,
    method: "post"
  })
}

// 禾蛙引流留资列表
export function hewaTrafficList(param) {
  return xhr({
    url: `admin/v1/gbox-user/hewa-traffic-list`,
    body: param,
    method: "get"
  })
}
